import React from "react";
import { Helmet } from 'react-helmet-async';



const Gdpr = ()=>{
	const canonicalUrl = `${window.location.href}`;

	return (
		<>
		 <Helmet>
			<title>GDPR</title>
			<link rel="canonical" href={`${canonicalUrl}`} />
		</Helmet>
		<section class="dashbaord pt-md-4">
			<div class="container py-4">
				<h3 class="fs-20 fw-bold text-black text-start py-3">GDPR Privacy Notice</h3>
				<div class="row">
				<div class="col-12">
					<p class="d-block lh-150 py-2">This notice is for people who are located in the European Economic Area (“EEA”) and supplements our general <a href="javascript:;" class="text-primary-ben">Privacy Policy</a>.
					<br /><br />
					Our processing of personal data of people who are in the EEA is governed by the General Data Protection Regulation (the “GDPR”), which applies from May 25, 2018. The GDPR requires us to provide certain information to you about your personal data, which we refer to in this notice as your personal information.</p>
					<strong class="d-block fs-5 mt-4">Data controller</strong>
					<p class="d-block lh-150 py-4">The data controller for this website is the Statpoint Support Services Limited. For our contact information, see the section in our general <a href="javascript:;" class="text-primary-ben">Privacy Policy</a> under “How to Contact Us”.</p>
					<strong class="d-block fs-5 mt-2">Purposes of the processing</strong>
					<p class="d-block lh-150 py-4">Personal information gathered through cookies is used for the purposes described in our “IMPORTANT NOTICE REGARDING ‘COOKIES’” (the “Cookies Notice”) of our <a href="javascript:;" class="text-primary-ben">Privacy Policy</a>. Other than information described in our Cookies Notice, the only information collected through our Site is personal information provided voluntarily by visitors.
					<br /><br />When visitors use our Site, we use the personal information they provide solely for purposes of enhancing the level of service we provide to them. For example, such improvements may consist of offering them a smoother checkout experience when making purchases from the Site, the ability to view their order history on the Site and our offering to them of potentially exclusive Site-related deals and other opportunities.</p>
					<strong class="d-block fs-5 mt-2">Lawful basis for the processing</strong>
					<p class="d-block lh-150 py-4">/Generally, we process personal information provided by visitors to our Site on the basis of consent.
					<br /><br />
					We may also process personal information on other bases permitted by the GDPR and applicable laws, such as when the processing is necessary for us to comply with our legal obligations.</p>
					<strong class="d-block fs-5 mt-2">Categories of personal information</strong>
					<p class="d-block lh-150 py-4">We process the following information when provided voluntarily by our website visitors: name, business title, email address, postal address and/or country, and telephone number. We also process automatically-gathered information as described in “IMPORTANT NOTICE REGARDING ‘COOKIES’” (the “Cookies Notice”) in our <a href="javascript:;" class="text-primary-ben">Privacy Policy</a>. Cookies that are necessary to display content or perform other functions you have requested, or that are necessary for the security or basic operation of our website, are examples of Cookies which may be applicable to your usage of the Site.</p>
					<strong class="d-block fs-5 mt-2">Recipients of your personal information</strong>
					<p class="d-block lh-150 py-4">We may use various service providers to manage our Site and provide services such as registration and check-out services and check-out. Our service providers may change from time to time. Note that our service providers have entered into contracts with us that restrict what they can do with your personal information. If you would like specific information about our service providers who have received your information, please contact us at support@bengp.com and we will provide that information to you.</p>
					<strong class="d-block fs-5 mt-2">Information regarding the transfers of personal data outside of the European Economic Area (EEA)</strong>
					<p class="d-block lh-150 py-4">We process personal information collected through our website in the United States. When you provide personal information to us, we request your consent to transfer that personal information to the United States. The United States does not have an adequacy decision from the European Commission, which means that the Commission has not determined that the laws of the United States provide adequate protection for personal information. Although the laws of the United States do not provide legal protection that is equivalent to EU data protection laws, we safeguard your personal information by treating it in accordance with this GDPR Privacy Notice. We take appropriate steps to protect your privacy and implement reasonable security measures to protect your personal information in storage. We use secure transmission methods to collect personal data through our website. We also enter into contracts with our data processors that require them to treat personal information in a manner that is consistent with this GDPR Notice.</p>
					<strong class="d-block fs-5 mt-2">Retention period for personal information</strong>
					<p class="d-block lh-150 py-4">How long we retain personal information varies according to the type of information in question and the purpose for which it is used. We delete personal information within a reasonable period after we no longer need to use it for the purpose for which it was collected (or for any subsequent purpose that is compatible with the original purpose). This does not affect your right to request that we delete your personal data before the end of its retention period. We may archive personal data (which means storing it in inactive files) for a certain period prior to its final deletion, as part of our ordinary business continuity procedures.
					<br /><br />
					Your rights to access, correct, restrict or delete your personal data and object to processing You have the right to request access to your personal data, to have your personal data corrected, restricted or deleted, and to object to our processing of your personal data. You also have the right of data portability, which means that you can request that we provide you (or a third party you designate) with a transferable copy of personal information that you have provided to us. Your rights may be subject to various limitations under the GDPR. If you wish to exercise any of these rights, or if you have any concerns about our processing of your personal data, please contact us in any of the ways listed in the section “How to Contact Us” in our general <a href="javascript:;" class="text-primary-ben">Privacy Policy</a>.</p>
					<strong class="d-block fs-5 mt-2">The right to lodge a complaint with a supervisory authority</strong>
					<p class="d-block lh-150 py-4">You have the right to file a complaint concerning our processing of your personal data with your national (or in some countries, regional) data protection authority. The EU Commission has a list here: http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm
					<br /><br />
					Absence of statutory or contractual requirement or other obligation to provide any personal data
					<br /><br />
					Users of our Site are under no statutory or contractual requirement or other obligation to provide personal information to us via our Site.</p>
				</div>
				</div>
			</div>
		</section>
		</>
	);
}
export default Gdpr;