import React from "react";
import {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from '../../website-assets/images/logo.svg'
import solider from '../../website-assets/images/soldier.png'
import { register } from "../services/api";
import { googleLogin, login } from "../services/api";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { getChat } from "../services/api";

const Register=()=>{
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const responseMessage = async(response) => {
		const decodedToken = jwtDecode(response.credential);
		if(decodedToken){
			setLoading(true);
		   const formData = new FormData(); 			
		   formData.append('email',decodedToken.email);
		   formData.append('sub',decodedToken.sub);
		   formData.append('name',decodedToken.name);
		   const response = await googleLogin(formData);
		   if(response.data.token){
			   setSuccessMessage('Login successful!');
			   sessionStorage.setItem('token', response.data.token);
			   sessionStorage.setItem('user_id', response.data.user.id);
			   sessionStorage.setItem('user_name', response.data.user.name);
			   sessionStorage.setItem('user_email', response.data.user.email);
			   const response = await getChat(response.data.token, response.data.user.id);
				console.log(response);
			   const redirectPath = sessionStorage.getItem('redirectPath') || '/';
			   sessionStorage.removeItem('redirectPath');
			   setLoading(false);
			   navigate(redirectPath);
		   }
		   else{
			   setErrors({ apiError: response.data.error });
		   }
		}
   };
   const errorMessage = (error) => {
	   console.log(error);
   };
	useEffect(() => {
		const isAuthenticated = sessionStorage.getItem('token');
		if (isAuthenticated) {
		  navigate('/');
		}
	  }, []);
	  const [formData, setFormData] = useState({
		name: '',
		email: '',
		password: '',
		c_password: '',
	  });
		const [errors, setErrors] = useState({});
		const [successMessage, setSuccessMessage] = useState('');
		const handleChange = (e) => {
			const { name, value, type, checked } = e.target;
			setFormData({
			  ...formData,
			  [name]: type === 'checkbox' ? checked : value
			});
		  };
		  const validate = () => {
			let errors = {};
		
			if (!formData.email) {
			  errors.email = 'Email is required';
			} else if (!/\S+@\S+\.\S+/.test(formData.email)) {
			  errors.email = 'Email address is invalid';
			}
			if (!formData.password) {
			  errors.password = 'Password is required';
			} else if (formData.password.length < 6) {
			  errors.password = 'Password must be at least 6 characters';
			}
			if(formData.password !== formData.c_password){
				errors.c_password = "Password does not match";
			}
			setErrors(errors);
		
			return Object.keys(errors).length === 0;
		  };
		  const handleSubmit = async (e) => {
			e.preventDefault();
			if (validate()) {
			  try {
				const response = await register(formData);
				if(response.data.token){
					setSuccessMessage('Registration successful!');
					sessionStorage.setItem('token', response.data.token);
					sessionStorage.setItem('user_id', response.data.user.id);
					sessionStorage.setItem('user_name', response.data.user.name);
					sessionStorage.setItem('user_email', response.data.user.email);
					const redirectPath = sessionStorage.getItem('redirectPath') || '/';
					sessionStorage.removeItem('redirectPath');
					navigate(redirectPath);
				}
				else{
					setErrors({ apiError: response.data.error });
				}
			
			  } catch (error) {
				console.error('Error during registration:', error.response.data);
				setErrors({ apiError: error.response.data.message });
			  }
			}
		  };

	return (
		<div className="login-register">
		<em class="over position-absolute bg-black opacity-75 bottom-0 top-0 end-0 start-0"></em>
		<div class="container vh-100 position-relative z-1">
		<div class="row align-items-center h-100">
			<div class="col-lg-2 col-md-1"></div>
			<div class="col-lg-8 col-md-10">
			<section class="bg-dark-ben rounded-3 overflow-hidden shadow p-4 my-4">
				<div class="row">
				<div class="col-md-12">
				{loading?<>
						<div class="text-center my-4"><Link to="/"><img src={Logo} alt="Logo" class="img-fluid" width="130" /></Link><br /><h1 class="h3 fw-bold text-white py-4">Login</h1></div>
						<div className="text-center loading-div my-4">
							<div className="spinner-border text-warning" role="status"></div>
							<br></br>
							<div className="loader-text mt-4">
							<span className="text-warning mt-4">Attempting to Register</span>
							</div>
						</div>
							</>:<><div class="text-center my-4"><Link to="/"><img src={Logo} alt="Logo" class="img-fluid" width="130" /></Link><br /><h1 class="h3 fw-bold text-white py-4">Get Registeration</h1></div>
							<form onSubmit={handleSubmit}>
							{errors.apiError && <span className="text-danger">{errors.apiError}</span>}
							{errors.name && <span className="text-danger">{errors.name}</span>}
							<div class="input-group mb-4 mt-2 bg-light-ben">
								<input type="text" class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben" name="name" placeholder="Enter Your Name*" value={formData.name} onChange={handleChange} />
							</div>
							{errors.email && <span className="text-danger">{errors.email}</span>}
							<div class="input-group mb-4 mt-2 bg-light-ben">
								<input type="text" class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben" name="email" placeholder="Enter Your Email*" value={formData.email} onChange={handleChange} />

							</div>
							{errors.password && <span className="text-danger">{errors.password}</span>}
							<div class="input-group mb-4 mt-2 bg-light-ben">
								<input type="password" class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben" name="password" placeholder="Enter Your Password*" value={formData.password} onChange={handleChange}/>
							</div>
							{errors.c_password && <span className="text-danger">{errors.c_password}</span>}
							<div class="input-group mb-4 mt-2 bg-light-ben">
								

								<input type="password" class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben" name="c_password" placeholder="Confirm Your Password*" value={formData.c_password} onChange={handleChange} />
							</div>
							<div class="form-check mb-4 mt-2">
								<input class="form-check-input mt-0 rounded-0" type="checkbox" value="" id="terms" />
								<label class="form-check-label text-light-ben fs-12" for="terms">I have read and accept the Terms of Service and Refund Policy. *</label>
							</div>
							<div class="mb-4 mt-2">
								<button name="btn" class="btn btn-primary-ben w-100 rounded-0 fw-bold p-3 text-uppercase">Register</button>
							</div>
							<div class="mb-4 mt-2 d-flex justify-content-center w-100">
								<GoogleLogin  onSuccess={responseMessage} onError={errorMessage} />
							</div>
							<div class="mb-4 mt-2 text-center">
								<p class="text-light-ben">Already have an Account? <Link to="/login" class="text-white fw-bol text-uppercase">Login</Link></p>
							</div>
							</form></>}
					
				</div>
				{/* <div class="col-md-6 ps-md-6 text-center pt-4">
					<img  src={solider} class="soldier img-fluid opacity-75" alt="Soldier" width="220" />
					<strong class="d-block text-light-ben pt-5 lh-150">Fast and Easy Delivery - Multiple Delivery Methods</strong>
				</div> */}
				</div>
			</section>
			</div>
			<div class="col-lg-2 col-md-1"></div>
		</div>
		</div>

		</div>
	);

}
export default Register;