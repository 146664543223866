import React, {useEffect, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from '../../website-assets/images/logo.svg'
import solider from '../../website-assets/images/soldier.png'
import { googleLogin, login } from "../services/api";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { getChat } from "../services/api";

const Login=()=>{
	const navigate = useNavigate();
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [successMessage, setSuccessMessage]= useState('');

	const [formData, setFormData] = useState({
		email:'',
		password:''
	});
	const responseMessage = async(response) => {
         const decodedToken = jwtDecode(response.credential);
		 if(decodedToken){
			setLoading(true);
			const formData = new FormData(); 			
			formData.append('email',decodedToken.email);
			formData.append('sub',decodedToken.sub);
			formData.append('name',decodedToken.name);
			const response = await googleLogin(formData);
			if(response.data.token){
				setSuccessMessage('Login successful!');
				sessionStorage.setItem('token', response.data.token);
				sessionStorage.setItem('user_id', response.data.user.id);
				sessionStorage.setItem('user_name', response.data.user.name);
				sessionStorage.setItem('user_email', response.data.user.email);
				const profileImage = `${process.env.REACT_APP_API_BASE_URL}/uploads/${response.data.user.profile_image}`;
				sessionStorage.setItem('profile_image',profileImage);
				
				const redirectPath = sessionStorage.getItem('redirectPath') || '/';
				sessionStorage.removeItem('redirectPath');
				setLoading(false);
				navigate(redirectPath);
			}
			else{
				setErrors({ apiError: response.data.error });
			}
		 }
    };
    const errorMessage = (error) => {
        console.log(error);
    };
	useEffect(() => {
		const isAuthenticated = sessionStorage.getItem('token');
		if (isAuthenticated) {
		  navigate('/');
		}
	  }, []);
	const handlechange=(e)=>{
		const {name, value, type, checked} = e.target;
		setFormData({
			...formData,
			[name]:type === 'checkbox'? checked:value
		});
	}
	const validate = ()=>{
		let errors={};
		if (!formData.email) {
			errors.email = 'Email is required';
		  } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
			errors.email = 'Email address is invalid';
		  }
		  if (!formData.password) {
			errors.password = 'Password is required';
		  } else if (formData.password.length < 6) {
			errors.password = 'Password must be at least 6 characters';
		  }
		  setErrors(errors);
	  
		  return Object.keys(errors).length === 0;
	}
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (validate()) {
		  try {
			const response = await login(formData);
			if(response.data.token){
				setSuccessMessage('Login successful!');
				sessionStorage.setItem('token', response.data.token);
				sessionStorage.setItem('user_id', response.data.user.id);
				sessionStorage.setItem('user_name', response.data.user.name);
				sessionStorage.setItem('user_email', response.data.user.email);
				const chats = await getChat(response.data.token, response.data.user.id);
				sessionStorage.setItem('chats',chats.data);
				const redirectPath = sessionStorage.getItem('redirectPath') || '/';
				sessionStorage.removeItem('redirectPath');
				navigate(redirectPath);
			}
			else{
				setErrors({ apiError: response.data.error });
			}
		
		  } catch (error) {
			setErrors({ apiError: error.response.data.error });
		  }
		}
	};
	return (
		<div className="login-register">
			<em class="over position-absolute bg-black opacity-75 bottom-0 top-0 end-0 start-0"></em>
			<div class="container vh-100 position-relative z-1">
			<div class="row align-items-center h-100">
				<div class="col-lg-2 col-md-1"></div>
				<div class="col-lg-8 col-md-10">
				<section class="bg-dark-ben rounded-3 overflow-hidden shadow p-4 my-4">
					<div class="row">
					
					<div class="col-md-12">
						{loading?<>
								<div class="text-center my-4"><Link to="/"><img src={Logo} alt="Logo" class="img-fluid" width="130" /></Link><br /><h1 class="h3 fw-bold text-white py-4">Login</h1></div>
								<div className="text-center loading-div my-4">
									<div className="spinner-border text-warning" role="status"></div>
									<br></br>
									<div className="loader-text mt-4">
									<span className="text-warning mt-4">Attempting to Login</span>
									</div>
								</div>
							</>:<><div class="text-center my-4"><Link to="/"><img src={Logo} alt="Logo" class="img-fluid" width="130" /></Link><br /><h1 class="h3 fw-bold text-white py-4">Login</h1></div>
						<form onSubmit={handleSubmit}>
						{errors.apiError && <span className="text-danger">{errors.apiError}</span>}
						<p>{successMessage && <span className="text-success">{successMessage}</span>}</p>
						{errors.email && <span className="text-danger">{errors.email}*</span>}
						<div class="input-group mb-4 mt-2 bg-light-ben">
							<input type="text" class="shadow-none form-control rounded-0 bg-light-ben border-light-ben " name="email" placeholder="Enter Your Email*" value={formData.email} onChange={handlechange} />
						</div>
						{errors.password && <span className="text-danger">{errors.password}*</span>}
						<div class="input-group mb-4 mt-2 bg-light-ben">
							<input type="password" class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben" name="password" placeholder="Enter Your Password*" value={formData.password} onChange={handlechange}/>
						</div>
						<div class="d-flex justify-content-between align-items-center">
							<div class="form-check mb-4 mt-2">
							<input class="form-check-input mt-0 rounded-0" type="checkbox" value="" id="terms" />
							<label class="form-check-label text-light-ben fs-12" for="terms">Remember Me</label>
							</div>
							<a href="https://app.thebengp.com/forget/password" class="text-light-ben fs-12 mt-n1">Forgot Password?</a>
						</div>
						<div class="mb-4 mt-2">
							<button name="btn" class="btn btn-primary-ben w-100 rounded-0 fw-bold p-3 text-uppercase">Login</button>
						</div>
						<div class="mb-4 mt-2 d-flex justify-content-center w-100">
						<GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
						</div>
						<div class="mb-4 mt-2 text-center">
							<p class="text-light-ben">Don't have an Account? <Link to="/register" class="text-white fw-bol text-uppercase">Register</Link></p>
						</div>
						</form></>}
						
					</div>
					{/* <div class="col-md-6 ps-md-6 text-center pt-4">
						<img src={solider} class="soldier img-fluid opacity-75" alt="Soldier" width="200" />
						<strong class="d-block text-light-ben pt-5 lh-150">Fast and Easy Delivery - Multiple Delivery Methods</strong>
					</div> */}
					</div>
				</section>
				</div>
				<div class="col-lg-2 col-md-1"></div>
			</div>
			</div>
		</div>
	);
}

export default Login;