import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getBlog } from "../services/api";
import { Helmet } from 'react-helmet-async';


const BlogDetail =(props)=>{
	const { id } = useParams();
	const [blogitem, setBlogItem] = useState();
	const [loader, setLoader] = useState(true);
	
	useEffect(()=>{
			fetchBlog();
			if(blogitem){
				props.setMetaDescription(blogitem.meta_description);
				props.setMetaTitle(blogitem.meta_title);
			}
	},[]);

	const fetchBlog =async()=>{
		setLoader(true);
		const response = await getBlog(id);
		setBlogItem(response.data);
		setLoader(false);
	}

	return (

		<>{loader ? (
			<>
			  <div className="loader-card text-center my-4 h-20">
				<div
				  className="spinner-border text-warning"
				  role="status"
				></div>
			  </div>
			</>
		  ):(
		  <section class="dashbaord pt-md-4">
			 <Helmet>
				<title>{blogitem.meta_title}</title>
				<meta name="description" content={blogitem.meta_description} />
			</Helmet>
				<div class="container py-4">
					<h3 class="fs-20 fw-bold text-black text-start py-3">{blogitem.title}</h3>
					<div class="d-flex gap-3 align-items-center">
					<a class="d-flex gap-2 text-dark fs-13" href="javascript:;"><i class="fa fa-user text-primary-ben" title="Posted By"></i> <span>{blogitem.author}</span></a>
					<a class="d-flex gap-2 text-dark fs-13" href="javascript:;"><i class="fa fa-calendar-alt text-primary-ben" title="Posted Date"></i> <span>{blogitem.publish_date}</span></a>
					<a class="d-flex gap-2 text-dark fs-13" href="javascript:;"><i class="fa fa-tag text-primary-ben" title="Category"></i> <span> {blogitem.tags 
						? JSON.parse(blogitem.tags).map(tag => tag.value).join(', ') 
						: 'No tags available'}</span></a>
					</div>
					<div class="row">
					<div class="col-12 mt-4">
						<div class="post-detials">
						<img src={`${process.env.REACT_APP_API_BASE_URL}${blogitem.image}`} alt={blogitem.img_alt} class="img-fluid" />
						<p class="d-block lh-150 py-4" dangerouslySetInnerHTML={{
						__html: blogitem.description,
					  }}></p>						
						</div>
					</div>
					</div>
				</div>
			</section>)}
		
		</>
	);

}
export default BlogDetail;