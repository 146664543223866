import React, { useEffect, useState, Suspense, lazy } from "react";

import { ToastContainer, toast } from "react-toastify";

import osrs from "../website-assets/images/OSRS.svg";
import rs3 from "../website-assets/images/RS3.svg";
import dash from "../website-assets/images/dash.svg";
import { Helmet } from 'react-helmet-async';


import {
  osrsAccounts,
  rs3Accounts,
  osrsProducts,
  rs3Products,
  osrsgoldRate,
  rs3goldRate,
  currencies
} from "./services/api";
const OsrsGold = lazy(() => import("./widgets/OsrsGold"));
const OsrsAccounts = lazy(() => import("./widgets/OsrsAccounts"));
const Rs3Accounts = lazy(() => import("./widgets/Rs3Accounts"));
const Rs3Items = lazy(() => import("./widgets/Rs3Items"));
const OsrsItems = lazy(() => import("./widgets/OsrsItems"));
const Rs3Gold = lazy(() => import("./widgets/Rs3Gold"));

const Home = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [contentloader, setContentLoader] = useState(false);
  const [message, setMessage] = useState("");
  const canonicalUrl = `${window.location.href}`;
  const description = "This is a description of the page content";

  const notify = () =>
    toast("Order Placed Successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const [data, setData] = useState({
    osrsaccounts: [],
    rs3accounts: [],
    osrsproducts: [],
    rs3products: [],
    osrsgoldrate: [],
    rs3goldRate: [],
    currencies:[]
  });

  useEffect(() => {
    sessionStorage.removeItem("orderGenerated");
    fetchData();
   
  }, []);

  

  const fetchData = async () => {
    try {
      setContentLoader(true);
      const [
        rs3goldResponse,
        osrsgoldResponse,
        osrsAccResponse,
        rs3AccResponse,
        osrsProdResponse,
        rs3ProdResponse,
        curenciesResponse
      ] = await Promise.all([
        rs3goldRate(),
        osrsgoldRate(),
        osrsAccounts(),
        rs3Accounts(),
        osrsProducts(),
        rs3Products(),
        currencies()
      ]);

      setData({
        rs3goldRate: rs3goldResponse.data,
        osrsgoldrate: osrsgoldResponse.data,
        osrsaccounts: osrsAccResponse.data,
        rs3accounts: rs3AccResponse.data,
        osrsproducts: osrsProdResponse.data,
        rs3products: rs3ProdResponse.data,
        currencies: curenciesResponse.data
      });
      setContentLoader(false);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  const renderComponent = () => {
    switch (props.component) {
      case "osrsGold":
        return (
          <OsrsGold
            setData= {setData}
            gold={data.rs3goldRate}
            setMessage={props.setFirstMessage}
            setShowChat={props.setShowChat}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            currencies = {data.currencies}
            contentloader={contentloader}
            setMetaDescription={props.setMetaDescription} setMetaTitle={props.setMetaTitle} setMetaUrl={props.setMetaUrl}
          />
        );
      case "rs3Gold":
        return (
          <Rs3Gold
            setData= {setData}
            gold={data.osrsgoldrate}
            setMessage={props.setFirstMessage}
            setShowChat={props.setShowChat}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            currencies = {data.currencies}
            contentloader={contentloader}
            setMetaDescription={props.setMetaDescription} setMetaTitle={props.setMetaTitle} setMetaUrl={props.setMetaUrl}
          />
        );
      case "osrsAccounts":
        return (
          <OsrsAccounts
            accounts={data.osrsaccounts}
            setMessage={props.setFirstMessage}
            setShowChat={props.setShowChat}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            contentloader={contentloader}
          />
        );
      case "rs3Accounts":
        return (
          <Rs3Accounts
            accounts={data.rs3accounts}
            setMessage={props.setFirstMessage}
            setShowChat={props.setShowChat}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            contentloader={contentloader}
          />
        );
      case "osrsItems":
        return (
          <OsrsItems
            products={data.osrsproducts}
            setMessage={props.setFirstMessage}
            setShowChat={props.setShowChat}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            contentloader={contentloader}
          />
        );
      case "rs3Items":
        return (
          <Rs3Items
            products={data.rs3products}
            setMessage={props.setFirstMessage}
            setShowChat={props.setShowChat}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            contentloader={contentloader}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
    
     <ToastContainer />
      <section class="slider position-absolute end-0 start-0">
        {/* <img
          src={solider}
          alt="Soldier"
          class="img-fluid position-absolute soldier start-0 end-0 mx-auto z-2"
        /> */}
        <div
          id="homeCarousel"
          class="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item slideone active">
              <em class="position-absolute bg-black opacity-50 top-0 end-0 start-0 bottom-0"></em>
            </div>
            <div class="carousel-item slidetwo">
              <em class="position-absolute bg-black opacity-50 top-0 end-0 start-0 bottom-0"></em>
            </div>
            <div class="carousel-item slidethree">
              <em class="position-absolute bg-black opacity-50 top-0 end-0 start-0 bottom-0"></em>
            </div>
            <div class="carousel-item slidefour">
              <em class="position-absolute bg-black opacity-50 top-0 end-0 start-0 bottom-0"></em>
            </div>
            <div class="carousel-item slidefive">
              <em class="position-absolute bg-black opacity-50 top-0 end-0 start-0 bottom-0"></em>
            </div>
            <div class="carousel-item slidefive">
              <em class="position-absolute bg-black opacity-50 top-0 end-0 start-0 bottom-0"></em>
            </div>
          </div>
          <button
            class="carousel-control-prev z-2"
            type="button"
            data-bs-target="#homeCarousel"
            data-bs-slide="prev"
          >
            <i class="fa fa-angle-left"></i>
          </button>
          <button
            class="carousel-control-next z-2"
            type="button"
            data-bs-target="#homeCarousel"
            data-bs-slide="next"
          >
            <i class="fa fa-angle-right"></i>
          </button>
        </div>
      </section>
      <section class="slider slider-data position-relative px-lg-5">
        <div class="container h-100 text-white position-relative z-2">
          <div class="row align-items-center justify-content-between">
            <div class="col-lg-5 col-md-6 my-4">
              <h1 class="fs-41 fw-bold">
                Buy Our <span class="text-primary-ben">PREMIUM</span> Games
              </h1>
              <ul class="d-block pt-4">
                <li class="d-flex gap-3 align-items-center py-4">
                  <i class="fa fa-circle fs-8"></i>{" "}
                  <strong class="fw-bold fs-20">
                    No Registration Required
                  </strong>
                </li>
                <li class="d-flex gap-3 align-items-center py-4">
                  <i class="fa fa-circle fs-8"></i>{" "}
                  <strong class="fw-bold fs-20">Fast and Easy Delivery</strong>
                </li>
                <li class="d-flex gap-3 align-items-center py-4">
                  <i class="fa fa-circle fs-8"></i>{" "}
                  <strong class="fw-bold fs-20">
                    Using High level Accounts
                  </strong>
                </li>
                <li class="d-flex gap-3 align-items-center py-4">
                  <i class="fa fa-circle fs-8"></i>{" "}
                  <strong class="fw-bold fs-20">
                    Multiple Delivery Methods
                  </strong>
                </li>
              </ul>
              <div class="d-flex gap-4 align-items-center games-outer">
                <a href="" class="games mt-4 transition">
                  <img src={osrs} alt="OSRS" class="img-fluid" />
                </a>
                <img src={dash} alt="Hyphon" class="img-fluid mt-4" />
                <a href="" class="games mt-4 transition">
                  <img src={rs3} alt="RS3" class="img-fluid" />
                </a>
              </div>
            </div>
            <div class="col-lg-6 ps-lg-5 col-md-6 my-4">
              <Suspense fallback={<div>Loading...</div>}>
                {renderComponent()}
              </Suspense>
            </div>
          </div>
        </div>
      </section>
      <section class="faqs py-5">
        <div class="container">
          <div class="row">
            <div class="col-12 mb-2">
              <h3 class="fs-20 fw-bold text-black text-center">
                <i class="fa fa-fire-alt me-2 text-primary-ben"></i>FAQs
              </h3>
            </div>
            <div class="questions mt-5">
              <strong class="d-flex align-items-center gap-2 text-primary-ben fs-16">
                <i class="fa fa-question-circle"></i>{" "}
                <span>OSRS Buy Gold from BenGP</span>
              </strong>
              <p class="d-block mt-3 mb-0">
                BenGP prides itself on the ability to distribute OSRS gold on a
                global scale, whilst maintaining the lowest rates possible on
                the OSRS gold market. Here at BenGP we believe that we offer a
                unique buying experience, this comes in the form of a dedicated
                team that is on hand to deal with any queries a customer may
                have.
                <br />
                <br />
                Each employee of BenGP has many years of experience when it
                comes to selling or buying OSRS gold. With 100T+ OSRS gold
                traded to date, we are more than equipped to deliver a fast,
                reliable, and trusted service to all of our customers. OSRS gold
                prices can change every day depending how the market is moving
                daily. Old school runescape gold has always a real value that
                you can trade back to us anytime you want to!
              </p>
            </div>
            <div class="questions mt-5">
              <strong class="d-flex align-items-center gap-2 text-primary-ben fs-16">
                <i class="fa fa-question-circle"></i>{" "}
                <span>What is Old School or OSRS? </span>
              </strong>
              <p class="d-block mt-3 mb-0">
                Old school rs or OSRS is a massive multiplayer online
                role-playing game (MMOPRG) developed by the UK based Jagex.
                Adventurers set out to quest, skill, and most importantly earn
                GP or OSRS Gold. Players grind skills with the dream of
                achieving the highest possible skill level, being 99, and
                getting rewarded with a skill cape. OSRS is actually an August
                2007 rebuild of the game ‘osrs’ or “RS3’, however, it has
                undergone large quality of life improvements, along with new
                content. 
              </p>
            </div>
            <div class="questions mt-5">
              <strong class="d-flex align-items-center gap-2 text-primary-ben fs-16">
                <i class="fa fa-question-circle"></i>{" "}
                <span>What is OSRS Gold?  </span>
              </strong>
              <p class="d-block mt-3 mb-0">
                OSRS Gold is the digital in-game currency of the game OSRS. It
                allows players to buy better armor or buy supplies for skilling.
                OSRS Gold can be used to give any tradable in-game item value as
                well as other in-game services. Adventurers refer to one
                thousand coins as 1000 coins as ‘1k’, 1,000,000 coins as ‘1mil
                or 1m’, and even 1,000,000,000 as ‘1b’ or 1bil’. 
              </p>
            </div>
            <div class="questions mt-5">
              <strong class="d-flex align-items-center gap-2 text-primary-ben fs-16">
                <i class="fa fa-question-circle"></i>{" "}
                <span>Where can I Purchase Old School Gold?</span>
              </strong>
              <p class="d-block mt-3 mb-0">
                These days there are so many places on the internet where
                players can buy OSRS Gold. You might find gold sellers on
                discord or in google search however when you are shopping for
                osrs gold you will need a trusted source to obtain your gold or
                you risk yourself to lose your money to scammers.
                <br />
                <br />
                The best osrs gold site is no doubt AribaGold because you can
                shop for other gold offers but you won't find the best price as
                we have. We have a lot of loyal customers that is buying osrs
                gold everyday from us. You can easily pay with many option but
                the easier option to buy osrs gold is credit card.
              </p>
            </div>
            <div class="questions mt-5">
              <strong class="d-flex align-items-center gap-2 text-primary-ben fs-16">
                <i class="fa fa-question-circle"></i>{" "}
                <span>Why should I buy OSRS Gold?</span>
              </strong>
              <p class="d-block mt-3 mb-0">
                There are many reasons why players buy OSRS Gold. One of the
                main reasons players choose to buy Old School osrs Gold is to
                buy better weapons or armor or to help them skill faster by
                being able to buy supplies immediately.
                <br />
                <br />
                The repetitive nature of some skills and the game can mean
                players get bored and buy OStoorder to skip the repetitive
                actions and parts of the game. If players wish to have an
                enjoyable experience then they need to buy OSRS Gold to have the
                best possible gaming experience.
                <br />
                <br />
                Why wouldn’t you buy some cheap OSRS GP and make your IRL and
                gaming life so much better! RS Gold For Sale! We always have
                osrs gold for sale in our stocks. You will get the best service
                ever whenever you shop osrs gold from aribagold. After you're
                buying the rs gold for sale you can go visit Grand Exchange and
                find any item there to purchase with the osrs gold you would
                have!
              </p>
            </div>
            <div class="questions mt-5">
              <strong class="d-flex align-items-center gap-2 text-primary-ben fs-16">
                <i class="fa fa-question-circle"></i>{" "}
                <span>What can I do with OSRS GP?</span>
              </strong>
              <p class="d-block mt-3 mb-0">
                The opportunities are endless when you buy OSRS Gold. There are
                various things you can do when it comes to using your Gold. If
                you are lucky enough to find a good supplier or market and buy
                some Cheap OSRS Gold. Firstly, and probably the most obvious
                would be to keep your OSRS Gold and create a stack of Gold!!
                Boring but hey, it’s totally up to you.
                <br />
                <br />
                Alternatively, you could buy OSRS Bonds – these allow you to
                purchase membership in-game, unlock extra tools, and redeem for
                Runecoins. Once you are well adapted to your life of wealth and
                riches, you could spend your Gold on improving your skills and
                improving your character – the ultimate flex! If you get bored
                and want to quit the game you can sell osrs gold back to
                AribaGold and have your money instantly!
              </p>
            </div>
            <div class="questions mt-5">
              <strong class="d-flex align-items-center gap-2 text-primary-ben fs-16">
                <i class="fa fa-question-circle"></i>{" "}
                <span>How to be Safe while Purchasing OSRS Gold?</span>
              </strong>
              <p class="d-block mt-3 mb-0">
                Several factors come into play when safely purchasing OSRS Gold.
                Once you make the decision to buy OSRS Gold – the most important
                part is to do so safely. The consequences for not ensuring you
                are in the right when you decide to buy Old School osrs Gold can
                be dire, including account bans.
                <br />
                <br />
                When you search for websites to buy OSRS Gold there will be many
                3rd party services and websites in the search ranking that will
                claim to sell and offer the best price on gold. This is probably
                one of the more annoying things of sorting through when you
                decide to buy OSRS GP.
                <br />
                <br />
                It is worthwhile noting that many of these websites and
                marketplaces are actually committed to providing the best and
                safest experience to their customers. However, it is important
                to be wary when you are searching for cheap OSRS Gold. Many of
                the gold websites will use cheap prices or offers to attract
                players into buying from them, only to cheat them of both their
                hard-earned cash and their osrs gold.
                <br />
                <br />
                This is not to say that when you decide to buy OSRS Gold that
                all websites are sketchy or scammers because it’s simply not the
                case. When you buy Old School osrs gold it can most certainly be
                safe if you use a trusted website such as BenGP.
                <br />
                <br />
                This way you can have peace of mind and guarantee that you’re
                100% safe and protected from any possible scams or tricks.
                Alternatively, here are some awesome safety suggestions for
                buying gold safely; 1. Find a reliable and secure site that has
                a strong reputation and knowledge of the business.
                <br />
                <br />
                Don't trust the fake reviews on their website, check for 3rd
                party reviews on websites like Trustpilot. 2. When you buy OSRS
                GP we suggest avoiding using a VPN. The issue with VPNs is that
                you never know if the IP address you get is already blacklisted
                or sketchy. 3. Most importantly is to enjoy the game with your
                gold. Hundreds of osrs gold transactions are happening around
                the clock every day, and at BenGP we make sure that your
                experience is safe and enjoyable! 
              </p>
            </div>
            <div class="questions mt-5">
              <strong class="d-flex align-items-center gap-2 text-primary-ben fs-16">
                <i class="fa fa-question-circle"></i>{" "}
                <span>Why Purchase OSRS Gold from us? </span>
              </strong>
              <p class="d-block mt-3 mb-0">
                It can be almost impossible to distinguish the difference
                between scam websites and real gold selling websites. There is
                one thing you can do to ensure you are 100% safe when you decide
                to buy OSRS Gold. The secret is to buy Old School osrs Gold from
                us here at BenGP. We strongly believe that the safety of our
                customers is key to success even in the osrs gold industry.
                <br />
                <br />
                Most importantly, we support multiple payment methods, including
                PayPal, and the safety of your money can be guaranteed. At BenGP
                you can not only buy the cheapest OSRS Gold on the market but
                also the safest website on the market. Sadly, it is becoming
                common for people to get cheated while trying to buy OSRS Gold.
                <br />
                <br />
                With the readily available internet, there are people just
                waiting to scam and take your hard-earned cash, and with the
                number of players, it is becoming a real risk. However, there’s
                no need to be scared or afraid when you decide to purchase cheap
                OSRS Gold.
                <br />
                <br />
                BenGP is safe and secure for osrs gold buying and you can rest
                assured of this by having the option to process your purchase
                using your favorite cryptocurrency. That way if there are any
                issues such as your delivery is lost or you didn’t get anything,
                we would be more than happy to refund as soon as possible.
                <br />
                <br />
                There will be a lot of people trying to convince you to buy OSRS
                gold from their store. It is important to ignore these types of
                people as usually they are scammers or sketchy – they will tell
                you that they don’t want you to lose out on cheap OSRS GP.
                <br />
                <br />
                This is why it is important that when you decide to buy old
                school osrs gold that you stay aware and do not partake in an
                unfair trade with an unreliable seller. Here at BenGP, you can
                rest assured that we provide the safest and most reliable
                operations that will ensure that the customers’ needs are met
                and made to feel safe.
                <br />
                <br />
                It is important to know that you will not directly be banned for
                using our website to Buy OSRS Gold, everything is fair and legal
                in our operations. We hope for the best gaming experience for
                everyone! 
              </p>
            </div>
            <div class="questions mt-5">
              <strong class="d-flex align-items-center gap-2 text-primary-ben fs-16">
                <i class="fa fa-question-circle"></i>{" "}
                <span>How much Old School Gold should I buy?</span>
              </strong>
              <p class="d-block mt-3 mb-0">
                You should buy Old School osrs Gold because most importantly it
                saves time. If you don’t have much time to sit in one spot and
                play for hours on end (family commitments, work, pets, etc),
                spending that time farming gold can understandably feel wasted
                and time-consuming.
                <br />
                <br />
                This is particularly true if you work IRL, think how much you
                earn in one hour of work. The average hourly wage in the US is
                about $20. When you come to think of it, how much gold can you
                earn in one hour playing the game?
                <br />
                <br />
                Unless it’s considerably more than about 50mil/ hour, you are
                quite simply wasting your time. There are so many places to get
                cheap OSRS Gold so why wait and purchase some today! 
              </p>
            </div>
            <div class="questions mt-5">
              <strong class="d-flex align-items-center gap-2 text-primary-ben fs-16">
                <i class="fa fa-question-circle"></i>{" "}
                <span>How much does OSRS GP Cost?</span>
              </strong>
              <p class="d-block mt-3 mb-0">
                One of the more obvious factors, when you decide to buy OSRS
                Gold, is certainly the price. In saying this, the price of gold
                actually fluctuates funnily enough, just like real-life
                currency!
              </p>
            </div>
            <div class="questions mt-5">
              <strong class="d-flex align-items-center gap-2 text-primary-ben fs-16">
                <i class="fa fa-question-circle"></i>{" "}
                <span>Will You Purchase My OSRS Gold?</span>
              </strong>
              <p class="d-block mt-3 mb-0">
                Please contact our live support chat who will be more than happy
                to purchase your gold from you. We purchase hundreds of millions
                of OSRS gold each day, and pay out via PayPal or BTC. Also
                please let our live chat know how much OSRS gold you are looking
                to sell. You may click here to sell instantly.
              </p>
            </div>
            <div class="questions mt-5">
              <strong class="d-flex align-items-center gap-2 text-primary-ben fs-16">
                <i class="fa fa-question-circle"></i>{" "}
                <span>Looking For Something Else? </span>
              </strong>
              <p class="d-block mt-3 mb-0">
                If you are looking to purchase RS3 gold instead, please
                click here. If you are looking to rent a OSRS Maxed Main, please
                click here.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
