import "./website-assets/css/style.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useState } from "react";
import Login from "./components/auth/Login";
import Home from "./components/Home";
import Register from "./components/auth/Register";
import Accounts from "./components/Pages/Accounts";
import Items from "./components/Pages/Items";
import Layout from "./components/Layout";
import Dashboard from "./components/Pages/Dashboard";
import Profile from "./components/Pages/Profile";
import Checkout from "./components/Pages/Checkout";
import Gdpr from "./components/Pages/Gdpr";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy";
import TermsConditions from "./components/Pages/TermsConditions";
import Refund from "./components/Pages/Refund";
import Thankyou from "./components/Pages/Thankyou";
import Intercom from '@intercom/messenger-js-sdk';
import Detail from "./components/Pages/Detail";
import Blogs from "./components/Pages/Blogs";
import { Helmet } from 'react-helmet-async';
import BlogDetail from "./components/Pages/BlogDetail";


function App() {
  const [metaTitle, setMetaTitle]= useState("OSRS Gold | Buy OSRS Gold | OSRS Accounts");
  const [metaDescription, setMetaDescription]= useState("OSRS Gold | Buy OSRS Gold | OSRS Accounts | Sell OSRS Gold | Sell OSRS Gp | OSRS Staff | BenGP");
  const [metaurl, setMetaUrl] = useState("");
  Intercom({
    app_id: 'wusw8k2t',
  });
  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <link rel="canonical" href={`${metaurl}`} />
        <meta name="description" content={metaDescription} />
      </Helmet>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/checkout" element={<Checkout/>}/>
          <Route path="/thank-you" element={<Thankyou/>}/>
          <Route
            path="/"
            element={
              <Layout component="osrsGold">
                <Home component="osrsGold" setMetaDescription={setMetaDescription} setMetaTitle={setMetaTitle} setMetaUrl={setMetaUrl} />
              </Layout>
            }
          />
          <Route
            path="/rs3-gold"
            element={
              <Layout component="rs3Gold">
                <Home component="rs3Gold" setMetaDescription={setMetaDescription} setMetaTitle={setMetaTitle} setMetaUrl={setMetaUrl}  />
              </Layout>
            }
          />
          <Route
            path="/osrs-accounts"
            element={
              <Layout component="osrsAccounts">
                <Home component="osrsAccounts" setMetaDescription={setMetaDescription} setMetaTitle={setMetaTitle} setMetaUrl={setMetaUrl}  />
              </Layout>
            }
          />
          <Route
            path="/rs3-accounts"
            element={
              <Layout component="rs3Accounts">
                <Home component="rs3Accounts" setMetaDescription={setMetaDescription} setMetaTitle={setMetaTitle} setMetaUrl={setMetaUrl}  />
              </Layout>
            }
          />
          <Route
            path="/osrs-items"
            element={
              <Layout component="osrsItems">
                <Home component="osrsItems"  setMetaDescription={setMetaDescription} setMetaTitle={setMetaTitle} setMetaUrl={setMetaUrl} />
              </Layout>
            }
          />
          <Route
            path="/rs3-items"
            element={
              <Layout component="rs3Items">
                <Home component="rs3Items" setMetaDescription={setMetaDescription} setMetaTitle={setMetaTitle} setMetaUrl={setMetaUrl}  />
              </Layout>
            }
          />
          <Route
            path="/all-osrs-accounts"
            element={
              <Layout component="OSRS">
                <Accounts component="OSRS" setMetaDescription={setMetaDescription} setMetaTitle={setMetaTitle} setMetaUrl={setMetaUrl}  />
              </Layout>
            }
          />
          <Route
            path="/all-rs3-accounts"
            element={
              <Layout component="RS3">
                <Accounts component="RS3" setMetaDescription={setMetaDescription} setMetaTitle={setMetaTitle} setMetaUrl={setMetaUrl}  />
              </Layout>
            }
          />
          <Route
            path="/all-osrs-items"
            element={
              <Layout component="OSRS">
                <Items component="OSRS" setMetaDescription={setMetaDescription} setMetaTitle={setMetaTitle} setMetaUrl={setMetaUrl}  />
              </Layout>
            }
          />
          <Route
            path="/all-rs3-items"
            element={
              <Layout component="RS3">
                <Items component="RS3"  setMetaDescription={setMetaDescription} setMetaTitle={setMetaTitle} setMetaUrl={setMetaUrl} />
              </Layout>
            }
          />
          <Route
            path="/dashboard"
            element={
              <Layout>
                <Dashboard  setMetaDescription={setMetaDescription} setMetaTitle={setMetaTitle} setMetaUrl={setMetaUrl} />
              </Layout>
            }
          />
           <Route
            path="/GDPR-policy"
            element={
              <Layout>
                <Gdpr  setMetaDescription={setMetaDescription} setMetaTitle={setMetaTitle} setMetaUrl={setMetaUrl} />
              </Layout>
            }
          />
          <Route
            path="/blogs"
            element={
              <Layout>
                <Blogs  setMetaDescription={setMetaDescription} setMetaTitle={setMetaTitle} setMetaUrl={setMetaUrl} />
              </Layout>
            }
          />
          <Route
            path="/blogs/detail/:id"
            element={
              <Layout>
                <BlogDetail  setMetaDescription={setMetaDescription} setMetaTitle={setMetaTitle} setMetaUrl={setMetaUrl} />
              </Layout>
            }
          />
           <Route
            path="/privacy-policy"
            element={
              <Layout>
                <PrivacyPolicy  setMetaDescription={setMetaDescription} setMetaTitle={setMetaTitle} setMetaUrl={setMetaUrl} />
              </Layout>
            }
          />
           <Route
            path="/terms-condition"
            element={
              <Layout>
                <TermsConditions setMetaDescription={setMetaDescription} setMetaTitle={setMetaTitle} setMetaUrl={setMetaUrl}  />
              </Layout>
            }
          />
           <Route
            path="/refund-policy"
            element={
              <Layout>
                <Refund  setMetaDescription={setMetaDescription} setMetaTitle={setMetaTitle} setMetaUrl={setMetaUrl} />
              </Layout>
            }
          />
          <Route
            path="/profile"
            element={
              <Layout>
                <Profile  setMetaDescription={setMetaDescription} setMetaTitle={setMetaTitle} setMetaUrl={setMetaUrl}  />
              </Layout>
            }
          />

            <Route
            path="/detail/:id"
            element={
              <Layout>
                <Detail component="ACCOUNT" setMetaDescription={setMetaDescription} setMetaTitle={setMetaTitle} setMetaUrl={setMetaUrl} />
              </Layout>
            }
          />
           <Route
            path="/detail/item/:id"
            element={
              <Layout>
                <Detail component="ITEM" setMetaDescription={setMetaDescription} setMetaTitle={setMetaTitle} setMetaUrl={setMetaUrl} />
              </Layout>
            }
          />

        </Routes>
      </Router>
    </>
  );
}

export default App;
