import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { blogs } from "../services/api";

const Blogs =()=>{
	const [loader, setLoader] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [blogslist ,setBlogslist] = useState([]);
	useEffect(()=>{
		setLoader(true);
		getBlogs();
		setLoader(false);
	},[]);

	const getBlogs=async()=>{
		const response = await blogs();
		console.log(response);
		setBlogslist(response.data);

	}
	return (
		<>
		{loader ? (
                <>
                  <div className="loader-card text-center my-4 h-20">
                    <div
                      className="spinner-border text-warning"
                      role="status"
                    ></div>
                  </div>
                </>
              ):(	<section class="dashbaord pt-4">
						<div class="container py-4">
							<div class="d-sm-flex justify-content-between align-items-center">
							<h3 class="fs-20 fw-bold text-black text-center py-3"><i class="fa fa-fire-alt me-2 text-primary-ben"></i>Our Blog</h3>
							<div class="position-relative">
								<input type="text" class="form-control rounded-0" placeholder="Search" />
								<a href="javascript:;" class="text-dark position-absolute end-0 top-0 me-3 fs-12 search"><i class="fa fa-search"></i></a>
							</div>
							</div>
							<div class="row mt-4 align-items-stretch">
							{blogslist.map((blog) => {
								const truncateText = (html, limit) => {
									const tempDiv = document.createElement("div"); // Use a temporary DOM element
									tempDiv.innerHTML = html; // Parse the HTML string
									const plainText = tempDiv.textContent || tempDiv.innerText || ""; // Extract plain text
									return plainText.length > limit ? `${plainText.substring(0, limit)}...` : plainText;
								};

								return (
									<div className="col-md-4 mt-4" key={blog.id}>
									<div className="p-4 blog-post shadow transition">
										<Link to={`/blogs/detail/${blog.slug}`} className="d-block overflow-hidden">
										<img
											src={`${process.env.REACT_APP_API_BASE_URL}${blog.image}`}
											alt="Blog Post"
											className="img-fluid transition"
										/>
										</Link>
										<Link to={`/blogs/detail/${blog.slug}`} className="text-primary-ben h5 fw-bold my-3 d-block lh-150">
										{blog.title}
										</Link>
										<p className="d-block lh-150 opacity-75 truncate-text">
										{truncateText(blog.description, 150)}
										</p>
										<div className="d-flex mt-4 justify-content-between align-items-center">
										<small className="text-dark opacity-50 fs-13">{blog.publish_date}</small>
										<Link to={`/blogs/detail/${blog.slug}`} className="btn btn-primary-ben rounded-0">
											Read More
										</Link>
										</div>
									</div>
									</div>
								);
								})}
							{/* <div class="col-12 text-center py-5">
								<a href="javascript:;" class="btn btn-primary-ben py-3 px-5 rounded-0">Load More</a>
							</div> */}
							</div>
						</div>
					</section>) 
		}
	
		</>
	);
}
export default Blogs;