import React from "react";
import { useEffect, useState } from "react";
import { getUserOrders } from "../services/api";
import { getUpdatedCheckoutUrl } from "../services/api";
import { updateOrderPayment } from "../services/api";


const Dashboard = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchOrders();
    const queryParams = new URLSearchParams(window.location.search);
    if (Array.from(queryParams).length > 0) {
      const orderId = queryParams.get("order_id");
      const track = queryParams.get("tracker");
        updateOrderStatus(orderId,track);

    }
  }, []);

  const updateOrderStatus= async(orderId,track)=>{
    const response = await updateOrderPayment(orderId,track);

  } 

  const handlePayment=async(orderId)=>{
      const token = sessionStorage.getItem("token");
      const response = await getUpdatedCheckoutUrl(token, orderId);
      if(response){
        window.location.href = response.data.checkout.redirect;	
      }
  }

  const fetchOrders = async () => {
    const userId = sessionStorage.getItem("user_id");
    const token = sessionStorage.getItem("token");
    const formData = new FormData();
    formData.append("id", userId);
    const response = await getUserOrders(token, formData);
    console.log(response);
    setOrders(response.data.orders);
  };
  return (
    <>
      <section class="dashbaord">
        <div class="container py-4">
          <div class="d-md-flex justify-content-between align-items-center">
            <h3 class="fs-20 fw-bold text-black text-center py-3">
              <i class="fa fa-fire-alt me-2 text-primary-ben"></i>Dashboard
            </h3>
            <div class="d-flex gap-3 py-3">
              <select class="form-select rounded-0">
                <option>-- Sort By --</option>
                <option>Ascending</option>
                <option>Descending</option>
                <option>Recent</option>
                <option>Pending</option>
                <option>Deliverd</option>
              </select>
              <div class="position-relative">
                <input
                  type="text"
                  class="form-control rounded-0"
                  placeholder="Search"
                />
                <a
                  href="javascript:;"
                  class="text-dark position-absolute end-0 top-0 me-3 fs-12 search"
                >
                  <i class="fa fa-search"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-4">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="bg-dark text-white p-3 fs-13">Order Name</th>
                  <th class="bg-dark text-white p-3 fs-13">Ordered Date</th>
                  <th class="bg-dark text-white p-3 fs-13">Order Status</th>
                  <th class="bg-dark text-white p-3 fs-13">Quantity</th>
                  <th class="bg-dark text-white p-3 fs-13">Price ($)</th>
                  <th class="bg-dark text-white p-3 fs-13">Payment</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr>
                    <td class="p-3 fs-12">
                      <div class="d-flex gap-2 align-items-center">
                        <i class="fa fa-user fa-2x border border-secondary p-1 text-primary-ben rounded-1"></i>
                        <span class="fs-12">
                          <p></p>
                          <strong>{order.title}</strong>
                        </span>
                      </div>
                    </td>
                    <td class="p-3 fs-12">{order.created_at}</td>
                    <td class="p-3 fs-12">
                      <span
                        class={`fw-bold py-1 px-2 ${
                          order.status === "Completed"
                            ? "bg-success"
                            : order.status === "Pending"
                            ? "bg-warning"
                            : "bg-secondary"
                        }`}
                      > {console.log(order)}
                        {order.status}
                      </span>
                    </td>
                    <td class="p-3 fs-12">{order.quantity}</td>
                    <td class="p-3 fs-12">${order.price}</td>
                    <td class="p-3 fs-12">{order.status === "Pending"? <><button class="btn btn-success" onClick={()=>handlePayment(order.id)}>Pay</button></>:<>Paid
                    
                    </>}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <div class="d-lg-flex justify-content-between my-2">
            <div class="d-flex align-items-center gap-3 my-3">
              <p>Showing</p>
              <select class="form-select rounded-0">
                <option>10</option>
                <option>20</option>
                <option>30</option>
                <option>40</option>
                <option>50</option>
              </select>
              <p class="d-block mw-120">of 200</p>
            </div>
            <nav class="my-3 paginations">
              <ul class="pagination">
                <li class="page-item">
                  <a class="page-link py-2 px-3 rounded-0" href="javascript:;">
                    Previous
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link py-2 px-3 active" href="javascript:;">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link py-2 px-3" href="javascript:;">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link py-2 px-3" href="javascript:;">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link py-2 px-3 rounded-0" href="javascript:;">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div> */}
        </div>
      </section>
    </>
  );
};
export default Dashboard;
